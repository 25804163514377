import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    description: {
                        "Dashboard": "Dashboard",
                        "Accounts": "Accounts",
                        "LiveAccounts": "Live Accounts",
                        "DemoAccounts": "Demo Accounts",
                        "ChangePassword": "Change Password",
                        "Deposit": "Deposit",
                        "Withdrawals": "Withdrawals",
                        "Withdrawal": "Withdrawal",
                        "InternalTransfer": "Internal Transfer",
                        "PaymentHistory": "Payment History",
                        "TradeHistory": "Trade History",
                        "Platforms": "Platforms",
                        "Platform": "Platform",
                        "Documents": "Documents",
                        "LiveUpdates": "Live Updates",
                        "MarketUpdates": "Market Updates",
                        "MarketOverview": "Market Overview",
                        "EconomyCalander": "Economy Calander",
                        "Webinar": "Webinar",
                        "UpcomingWebinar": "Upcoming Webinar",
                        "Coaching": "Coaching",
                        "KYCStatus": "KYC Status",
                        "Pending": "Pending",
                        "Approved": "Approved",
                        "TradingStatus": "Trading Status",
                        "Disabled": "Disabled",
                        "Enabled": "Enabled",
                        "Profile": "Profile",
                        "SignOut": "Sign Out",
                        "BALANCE": "BALANCE",
                        "EQUITY": "EQUITY",
                        "DEPOSITS": "DEPOSITS",
                        "WITHDRAWAL": "WITHDRAWAL",
                        "TradingAccounts": "Trading Accounts",
                        "TradingPlatform": "Trading Platform",
                        "AccountNumber": "Account Number",                        
                        "Type": "Password Type",
                        "Balance": "Balance",
                        "Equity": "Equity",
                        "Stopout": "Stopout",
                        "Leverage": "Leverage",
                        "Action": "Action",
                        "Account": "Account",
                        "GoDo-Live": "GoDo-Live",
                        "GoDo-Server": "GoDo-Server",
                        "GoDo-Demo": "GoDo-Demo",
                        "Currency": "Currency",
                        "FundNow": "Fund Now",
                        "CreateAccount": "Create Account",
                        "NewPassword": "New Password",
                        "ConfirmNewPassword": "Confirm New Password",
                        "Submit": "Submit",
                        "CardPayment": "Card Instant Deposit",
                        "WireTransfer": "Wire Transfer",
                        "CryptoDetails": "Crypto Instant Deposit",
                        "PerfectMoney": "Perfect Money",
                        "SticPayment": "Stic Payment",
                        "Skrill": "Skrill",
                        "Neteller": "Neteller",
                        "WishMoney": "Wish Money",
                        "LocalDeposit": "Local Deposit",
                        "Morocco": "Morocco",
                        "DepositCurrency": "Deposit Currency",
                        "AmountInUSD": "Amount in USD",
                        "AmountInMAD": "Amount in MAD",
                        "Note": "Note",
                        "TradingAccount": "Trading Account",
                        "AfrAsiaBankUSD": "AfrAsia Bank USD",
                        "AfrAsiaBankEUR": "AfrAsia Bank EUR",
                        "AfrAsiaBankAED": "AfrAsia Bank AED",
                        "CBDBank": "CBD Bank",
                        "EqualsMoney": "Equals Money",
                        "Currencies": "Currencies",
                        "BeneficiaryName": "Beneficiary Name",
                        "BeneficiaryAccount": "Beneficiary Account",
                        "BeneficiaryAddress": "Beneficiary Address",
                        "BankName": "Bank Name",
                        "ID": "ID",
                        "BankAddress": "Bank Address",
                        "IBANNumber": "IBAN Number",
                        "SwiftCode": "Swift Code",
                        "MinimumAmount": "Minimum Amount",
                        "AccountForClient": "Account For Client",
                        "AfrAsiaBankLtd": "AfrAsia Bank Ltd",
                        "GODOLTD-CLIENTACCOUNT": "GODO LTD - CLIENT ACCOUNT",
                        "3rdFloor,StandardCharteredTower,Cybercity,Ebene,72201,Mauritius": "3rd Floor, Standard Chartered Tower, Cybercity, Ebene, 72201, Mauritius",
                        "Bowen Square 10, Dr Ferriere Street, Port Louis, Mauritius": "Bowen Square 10, Dr Ferriere Street, Port Louis, Mauritius",
                        "OnlineFunding": "Online Funding",
                        "Pleasenotecrypto": "Please note crypto deposits are accepted in USDT and USDC currencies only with the following conditions to protect the integrity of the transactions",
                        "The customer is responsible for any gas fees applied by the exchange": "The customer is responsible for any gas fees applied by the exchange",
                        "Withdrawals will be blocked for a period of 15 days unless the account has been cleared earlier by our Compliance department": "Withdrawals will be blocked for a period of 15 days unless the account has been cleared earlier by our Compliance department",
                        "All funds to be returned to original funding source": "All funds to be returned to original funding source",
                        "TradingAccountID": "Trading Account ID",
                        "DepositAmountinUSD": "Deposit Amount in USD",
                        "WalletAddress": "Wallet Address",
                        "Iacceptthe": "I accept the",
                        "TermsandConditionsforCreditCardDisclaimer": "Terms and Conditions for Credit Card Disclaimer",
                        "Next": "Next",
                        "PayeeAccount": "Payee Account",
                        "SupportService": "Support Service",
                        "Doyouhaveaquestion": "Do you have a question about deposit? Please contact",
                        "ourFinancialSupportService": "our Financial Support Service.",
                        "Information": "Information",
                        "Exchangeratesandcommission": "Exchange rates and commission",
                        "Paymentagentcontacts": "Payment agent contacts",
                        "AgentName": "Agent Name",
                        "MoroccoPaymentAgent": "Morocco Payment Agent",
                        "Website": "Website",
                        "WorkingHours": "Working Hours",
                        "WhishMoneyPaymentInstructions": "Whish Money Payment Instructions",
                        "Step-by-Step Instructions1. Download and Install the Wish Money App": "Step-by-Step Instructions1. Download and Install the Wish Money App",
                        "Visit the Wish Money App download page.": "Visit the Wish Money App download page.",
                        "Download and install the app on your mobile device.": "Download and install the app on your mobile device.",
                        "Login to the Wish Money App": "Login to the Wish Money App",
                        "Open the app.": "Open the app.",
                        "Enter your login credentials to access your account.": "Enter your login credentials to access your account.",
                        "Navigate to the Finance Category": "Navigate to the Finance Category",
                        "After logging in, choose the 'Finance' category from the menu options.": "After logging in, choose the 'Finance' category from the menu options.",
                        "Enter the Receiver's Phone Number": "Enter the Receiver's Phone Number",
                        "In the designated field, input the receiver's phone number: 71180175.": "In the designated field, input the receiver's phone number: 71180175.",
                        "Specify the Transfer Amount": "Specify the Transfer Amount",
                        "Enter the amount you wish to transfer in USD.": "Enter the amount you wish to transfer in USD.",
                        "Add a Note with Your Trading Account Details": "Add a Note with Your Trading Account Details",
                        "In the note field, enter your trading account number followed by - GoDoCM Dubai. Example: 12345678 - GoDoCM Dubai.": "In the note field, enter your trading account number followed by - GoDoCM Dubai. Example: 12345678 - GoDoCM Dubai.",
                        "Complete the Transaction": "Complete the Transaction",
                        "Review all the details to ensure accuracy.": "Review all the details to ensure accuracy.",
                        "Click the 'Send' button to complete the transaction.": "Click the 'Send' button to complete the transaction.",
                        "WithdrawalMethod": "Withdrawal Method",
                        "Amount": "Amount",
                        "ImportantInformation": "Important Information",
                        "Allfunds": "All funds will be returned to the original funding source.",
                        "Youareentitled": "You are entitled to one free withdrawal per month; after that, a standard withdrawal fee of $25 applies.",
                        "FromAccount": "From Account",
                        "ToAccount": "To Account",
                        "ReportType": "Report Type",
                        "DateFrom": "Date From",
                        "DateTo": "Date To",
                        "Search": "Search",
                        "Time": "Time",
                        "Status": "Status",
                        "PENDING": "PENDING",
                        "REJECTED": "REJECTED",
                        "APPROVED": "APPROVED",
                        "AccountType": "Account Type",
                        "PROCEED": "PROCEED",
                        "ViewTrades": "View Trades",
                        "Open Position": "Open Position",
                        "Close Position": "Close Position",
                        "Symbol": "Symbol",
                        "Ticket": "Ticket",
                        "DealType": "Deal Type",
                        "Volume": "Volume",
                        "Price": "Price",
                        "PriceOpen": "Price Open",
                        "PriceSI": "Price SI",
                        "PriceTP": "Price TP",
                        "PriceCurrent": "Price Current",
                        "Login": "Login",
                        "NoData": "No Data",
                        "DownloadsForMT5": "Downloads For MT5",
                        "DownloadsForMT4": "Downloads For MT4",
                        "DownloadNow": "Download Now",
                        "forAndroid": "for Android",
                        "forWindow": "for Window",
                        "forMAC": "for MAC",
                        "forPC": "for PC",
                        "UploadDocuments": "Upload Documents",
                        "ProofofID": "Proof of ID (Front page of passport OR Front and Back side of ID)",
                        "ProofofAddress": "Proof of Address",
                        "SourceofIncome": "Source of Income",
                        "AdditionalDocuments": "Additional Documents",
                        "ProofofIdentity": "Proof of Identity",
                        "CopyofPassport": "Copy of Passport",
                        "CopyofGovernmentIssuedID": "Copy of Government Issued ID",
                        "ProofofResidentialAddress": "Proof of Residential Address",
                        "UtilityBill": "Utility Bill",
                        "CurrentLease/TenancyAgreementSourceOfIncome": "Current Lease/Tenancy Agreement Source Of Income",
                        "Recentbankstatementshowingsalary": "Recent bank statement showing salary",
                        "Payslip": "Payslip",
                        "EmploymentContracttoshowyourincomefunds": "Employment Contract to show your income funds",
                        "NoteDocuments": "Note: Documents must be issued within the last 3 calendar months",
                        "Title": "Title",
                        "UploadedAt": "Uploaded At",
                        "KYCDocuments": "KYC Documents",
                        "OPENANEWDEMOACCOUNT": "OPEN A NEW DEMO ACCOUNT",
                        "OPENANEWLIVEACCOUNT": "OPEN A NEW LIVE ACCOUNT",
                        "File": "File",
                        "WhishMoneyInstructions": "To transfer funds using the Whish Money App, please follow the steps below:",
                        "StepByStepInstructions": "Step-by-Step Instructions",
                        "Step1": "1. Download and Install the Whish Money App:",
                        "Step1Details": "Visit the.",
                        "Step1Details1": "Whish Money App download page.",
                        "Step1Details2": "Download and install the app on your mobile device.",
                        "Step2": "2. Login to the Whish Money App:",
                        "Step2Details": "Open the app.",
                        "Step2Details1": "Enter your login credentials to access your account.",
                        "Step3": "3. Navigate to the Finance Category:",
                        "Step3Details": "After logging in, choose the 'Finance' category from the menu options.",
                        "Step4": "4. Enter the Receiver's Phone Number:",
                        "Step4Details": "In the designated field, input the receiver's phone number: 71180175.",
                        "Step5": "5. Specify the Transfer Amount:",
                        "Step5Details": "Enter the amount you wish to transfer in USD.",
                        "Step6": "6. Add a Note with Your Trading Account Details:",
                        "Step6Details": "In the note field, enter your trading account number followed by - GoDoCM Dubai.",
                        "Step6Details1": "Example: 12345678 - GoDoCM Dubai.",
                        "Step7": "7. Complete the Transaction:",
                        "Step7Details": "Review all the details to ensure accuracy.",
                        "Steps7Details1": "Click the 'Send' button to complete the transaction.",
                        "CryptoCurrency": "Crypto Currency",
                        "Network": "Network",
                        "Address": "Address",
                        "PleaseNoteMinimumWithdrawal": "Please note minimum withdrawal amount is 10$",
                        "AddWallet": "Add Wallet",
                        "Wallet": "Wallet",
                        "AddNewWallet": "Add New Wallet",
                        "Add": "Add",
                        "OpenPosition": "Open Position",
                        "ClosePosition": "Close Position",
                        "Profit": "Profit",
                        "Plateform": "Plateform",
                        "Iran": "Iran",
                        "DealId": "Deal Id",
                        "Entry": "Entry",
                        "Iraq": "Iraq",
                        "Turkey": "Turkey",
                        "Jordan": "Jordan",
                        "Pakistan": "Pakistan",
                        "India": "India",
                        "PayPal": "PayPal"
                    }
                }
            },
            ar: {
                translation: {
                    description: {
                        "Dashboard": "لوحة التحكم",
                        "Accounts": "الحسابات",
                        "LiveAccounts": "حساب حقيقي",
                        "DemoAccounts": "حساب تجريبي",
                        "ChangePassword": "تغيير كلمة المرور",
                        "Deposit": "الإيداع",
                        "Withdrawals": "السحب",
                        "Withdrawal": "السحب",
                        "WITHDRAWAL": "السحب",
                        "InternalTransfer": "تحويل داخلي",
                        "PaymentHistory": "تاريخ المدفوعات",
                        "TradeHistory": "تاريخ التداول",
                        "Platforms": "المنصات",
                        "Documents": "الوثائق",
                        "LiveUpdates": "التحديثات الحية",
                        "MarketUpdates": "تحديثات السوق",
                        "MarketOverview": "نظرة على الأسواق",
                        "EconomyCalander": "التقويم الاقتصادي",
                        "Webinar": "الندوات",
                        "UpcomingWebinar": "الندوة القادمة",
                        "Coaching": "التدريب",
                        "KYCStatus": "حالة KYC",
                        "Pending": "قيد الانتظار",
                        "Approved": "موافق عليه",
                        "TradingStatus": "حالة التداول",
                        "Disabled": "معطل",
                        "Enabled": "نشط",
                        "Profile": "الملف الشخصي",
                        "SignOut": "تسجيل الخروج",
                        "Balance": "رأس المال",
                        "Equity": "رأس المال الحالي",
                        "Deposits": "الودائع",
                        "DEPOSITS": "الودائع",
                        "WithdrawalS": "السحب",
                        "TradingAccounts": "حسابات التداول",
                        "AccountNumber": "رقم الحساب",
                        "Platform": "المنصة",
                        "Type": "نوع كلمة المرور",
                        "BALANCE": "رأس المال",
                        "EQUITY": "رأس المال الحالي",
                        "Stopout": "وقف الخسارة",
                        "Leverage": "الرافعة المالية",
                        "Action": "الإجراء",
                        "Account": "الحساب",
                        "GoDo-Live": "GoDo لايف",
                        "GoDo-Server": "GoDo الخادم",
                        "GoDo-Demo": "GoDo – التجريبي",
                        "Currency": "العملة",
                        "FundNow": "تمويل الحساب",
                        "CreateAccount": "إنشاء حساب جديد",
                        "NewPassword": "إنشاء كلمة مرور جديدة",
                        "ConfirmNewPassword": "تأكيد كلمة المرور الجديدة",
                        "Submit": "إرسال",
                        "CardPayment": "إيداع فوري بالبطاقة",
                        "WireTransfer": "التحويل البنكي",
                        "CryptoDetails": "إيداع فوري بالعملات الرقمية",
                        "PerfectMoney": "بيرفكت موني",
                        "SticPayment": "ستك بايمنت",
                        "Skrill": "سكريل",
                        "Neteller": "نتيلر",
                        "WishMoney": "ويش موني",
                        "LocalDeposit": "إيداع محلي",
                        "Morocco": "المغرب",
                        "DepositCurrency": "عملة الإيداع",
                        "AmountInUSD": "المبلغ بالدولار الأمريكي",
                        "AmountInMAD": "المبلغ بالدرهم المغربي",
                        "Note": "ملاحظة",
                        "TradingAccount": "حساب التداول",
                        "AfrAsiaBankUSD": "بنك أفراسيا بالدولار الأمريكي",
                        "AfrAsiaBankEUR": "بنك أفراسيا باليورو",
                        "AfrAsiaBankAED": "بنك أفراسيا بالدرهم الإماراتي",
                        "CBDBank": "بنك CBD",
                        "EqualsMoney": "إيكوالز موني",
                        "Currencies": "العملات",
                        "BeneficiaryName": "اسم البنك المستفيد",
                        "BeneficiaryAccount": "الحساب المستفيد",
                        "BeneficiaryAddress": "العنوان المستفيد",
                        "BankName": "اسم البنك",
                        "ID": "الهوية",
                        "BankAddress": "عنوان البنك",
                        "AccountNumbers": "رقم الحساب",
                        "IBANNumber": "رقم الآيبان",
                        "SwiftCode": "كود SWIFT",
                        "MinimumAmount": "الحد الأدنى للمبلغ",
                        "AccountForClient": "حساب العميل",
                        "AfrAsiaBankLtd": "بنك أفراسيا المحدود",
                        "GODOLTD-CLIENTACCOUNT": "GODO LTD - حساب العميل",
                        "3rdFloorStandardCharteredTowerCybercityEbene72201Mauritius": "الطابق الثالث، برج ستاندرد تشارترد، سايبر سيتي، إيبين، 72201، موريشيوس",
                        "BowenSquare10DrFerriereStreetPortLouisMauritius": "ميدان بوين 10، شارع الدكتور فيرير، بورت لويس، موريشيوس",
                        "OnlineFunding": "التمويل عبر الإنترنت",
                        "Pleasenotecrypto": "يرجى ملاحظة أن الودائع بالعملات الرقمية مقبولة فقط بعملتي USDT وUSDC مع الشروط التالية لحماية نزاهة المعاملات",
                        "CryptoDepositNote": "يرجى العلم أنه يتم قبول إيداعات العملات الرقمية بعملتي USDT وUSDC فقط مع الشروط التالية لضمان سلامة المعاملات",
                        "GasFeesNote": "العميل مسؤول عن أي رسوم معاملات يتم تطبيقها.",
                        "WithdrawalBlockNote": "سيتم حظر السحوبات لمدة 15 يومًا ما لم يتم مراجعة الحساب مسبقًا من قبل قسم الامتثال لدينا",
                        "FundsReturnNote": "جميع الأموال سيتم إعادتها إلى مصدر التمويل الأصلي",
                        "TradingAccountID": "معرف حساب التداول",
                        "DepositAmountInUSD": "مبلغ الإيداع بالدولار الأمريكي",
                        "WalletAddress": "عنوان المحفظة",
                        "IAcceptThe": "أوافق على",
                        "TermsAndConditionsForCreditCardDisclaimer": "شروط وأحكام إخلاء المسؤولية عن بطاقة الائتمان",
                        "Next": "التالي",
                        "PayeeAccount": "حساب المستفيد",
                        "SupportService": "خدمة الدعم",
                        "Doyouhaveaquestion": "هل لديك سؤال حول الإيداع؟ يرجى التواصل مع",
                        "ourFinancialSupportService": "خدمة الدعم المالي لدينا.",
                        "DepositQuestion": "هل لديك سؤال حول الإيداع؟ يرجى الاتصال بخدمة الدعم المالي لدينا.",
                        "Information": "معلومات",
                        "Exchangeratesandcommission": "أسعار الصرف والعمولات",
                        "Paymentagentcontacts": "جهات اتصال وكيل الدفع",
                        "ExchangeRatesAndCommission": "أسعار الصرف والعمولة",
                        "PaymentAgentContacts": "جهات اتصال وكيل الدفع",
                        "AgentName": "اسم الوكيل",
                        "MoroccoPaymentAgent": "وكيل الدفع في المغرب",
                        "Website": "الموقع الإلكتروني",
                        "WorkingHours": "ساعات العمل",
                        "WhishMoneyPaymentInstructions": "تعليمات دفع Whish Money",
                        "WishMoneyInstructions": "تعليمات تحميل وتثبيت تطبيق Wish Money",
                        "WishMoneyStep1": "قم بزيارة صفحة تنزيل تطبيق Wish Money",
                        "WishMoneyStep2": "قم بتنزيل التطبيق وتثبيته على جهازك المحمول.",
                        "WishMoneyLogin": "قم بتسجيل الدخول إلى تطبيق Wish Money",
                        "WishMoneyStep3": "افتح التطبيق.",
                        "WishMoneyStep4": "أدخل بيانات تسجيل الدخول الخاصة بك للوصول إلى حسابك.",
                        "WishMoneyFinance": "انتقل إلى الفئة المالية",
                        "WishMoneyStep5": "بعد تسجيل الدخول، اختر الفئة 'المالية' من خيارات القائمة",
                        "WishMoneyReceiverNumber": "أدخل رقم هاتف المستلم",
                        "WishMoneyStep6": "في الحقل المخصص، أدخل رقم هاتف المستلم: 71180175",
                        "WishMoneyTransferAmount": "حدد مبلغ التحويل",
                        "WishMoneyAmountInUSD": "أدخل المبلغ الذي ترغب في تحويله بالدولار الأمريكي.",
                        "WishMoneyNote": "أضف ملاحظة مع تفاصيل حساب التداول الخاص بك",
                        "WishMoneyTradingAccountNote": "في حقل الملاحظة، أدخل رقم حساب التداول الخاص بك متبوعًا بـ - GoDoCM Dubai. مثال: 12345678 - GoDoCM Dubai.",
                        "WishMoneyCompleteTransaction": "أكمل المعاملة",
                        "WishMoneyStep7": "راجع جميع التفاصيل للتأكد من صحتها.",
                        "WishMoneySend": "انقر فوق زر 'إرسال' لإكمال المعاملة.",
                        "WithdrawalMethod": "طريقة السحب",         
                        "Amount": "المبلغ",
                        "ImportantInformation": "معلومات هامة",
                        "Allfunds": "سيتم إعادة جميع الأموال إلى مصدر التمويل الأصلي.",
                        "Youareentitled": "يحق لك سحب واحد مجاني شهريًا؛ بعد ذلك، يتم تطبيق رسوم سحب قياسية قدرها 25 دولارًا.",
                        "FundsReturn": "سيتم إعادة جميع الأموال إلى مصدر التمويل الأصلي.",
                        "FreeWithdrawalNote": "يحق لك سحب مجاني واحد شهريًا؛ بعد ذلك، يتم تطبيق رسوم سحب قياسية قدرها 25 دولارًا.",
                        "FromAccount": "من الحساب",
                        "ToAccount": "إلى الحساب",
                        "ReportType": "نوع التقرير",
                        "DateFrom": "التاريخ من",
                        "DateTo": "التاريخ إلى",
                        "Search": "بحث",
                        "Time": "الوقت",
                        "Status": "الحالة",
                        "PENDING": "قيد الانتظار",
                        "Rejected": "مرفوض",
                        "APPROVED": "مقبول",
                        "AccountType": "نوع الحساب",
                        "ViewTrades": "عرض الصفقات",
                        "OpenPosition": "فتح مركز",
                        "ClosePosition": "إغلاق مركز",
                        "Symbol": "الرمز",
                        "Ticket": "التذكرة",
                        "DealType": "نوع الصفقة",
                        "Volume": "الحجم",
                        "Price": "السعر",
                        "PriceOpen": "سعر الفتح",
                        "PriceSI": "وقف الخسارة",
                        "PriceTP": "جني الأرباح",
                        "PriceCurrent": "السعر الحالي",
                        "Login": "تسجيل الدخول",
                        "NoData": "لا توجد بيانات",
                        "DownloadsForMT5": "التنزيلات لـ MT5",
                        "DownloadsForMT4": "التنزيلات لـ MT4",
                        "DownloadNow": "قم بالتنزيل الآن",
                        "forAndroid": "لأجهزة الأندرويد",
                        "forWindow": "لنظام ويندوز",
                        "forMAC": "لنظام ماك",
                        "forPC": "للكمبيوتر الشخصي",
                        "Margin": "الهامش",
                        "FreeMargin": "الهامش المتاح",
                        "MarginLevel": "مستوى الهامش",
                        "PL": "الأرباح/الخسائر",
                        "WhishMoneyInstructions": "لتحويل الأموال باستخدام تطبيق Whish Money، يرجى اتباع الخطوات التالية:",
                        "StepByStepInstructions": "تعليمات خطوة بخطوة",
                        "Step1": "1. قم بتنزيل وتثبيت تطبيق Whish Money:",
                        "Step1Details": "قم بزيارة.",
                        "Step1Details1": "صفحة تنزيل تطبيق Whish Money.",
                        "Step1Details2": "قم بتنزيل التطبيق وتثبيته على جهازك المحمول.",
                        "Step2": "2. تسجيل الدخول إلى تطبيق Whish Money:",
                        "Step2Details": "افتح التطبيق.",
                        "Step2Details1": "أدخل بيانات تسجيل الدخول الخاصة بك للوصول إلى حسابك",
                        "Step3": "3. انتقل إلى فئة المالية:",
                        "Step3Details": "بعد تسجيل الدخول، اختر فئة 'المالية' من خيارات القائمة.",
                        "Step4": "4. أدخل رقم هاتف المستلم:",
                        "Step4Details": "في الحقل المخصص، أدخل رقم هاتف المستلم: 71180175.",
                        "Step5": "5. حدد مبلغ التحويل:",
                        "Step5Details": "أدخل المبلغ الذي ترغب في تحويله بالدولار الأمريكي.",
                        "Step6": "6. أضف ملاحظة تحتوي على تفاصيل حساب التداول الخاص بك:",
                        "Step6Details": "في حقل الملاحظات، أدخل رقم حساب التداول الخاص بك متبوعًا بـ - GoDoCM دبي.",
                        "Step6Details1": "مثال: 12345678 - GoDoCM دبي.",
                        "Step7": "7. أكمل المعاملة:",
                        "Step7Details": "راجع جميع التفاصيل للتأكد من صحتها.",
                        "Steps7Details1": "انقر على زر 'إرسال' لإتمام المعاملة.",
                        "CryptoCurrency": "العملة المشفرة",
                        "Network": "الشبكة",
                        "Address": "العنوان",
                        "PleaseNoteMinimumWithdrawal": "يرجى ملاحظة أن الحد الأدنى لمبلغ السحب هو 10 دولارات.",
                        "AddWallet": "إضافة محفظة",
                        "Wallet": "محفظة",
                        "AddNewWallet": "إضافة محفظة جديدة",
                        "Add": "إضافة",
                        "Profit": "الربح",
                        "KYCDocuments": "مستندات اعرف عميلك",
                        "UploadDocuments": "تحميل المستندات",
                        "ProofofID": "إثبات الهوية (الصفحة الأمامية من جواز السفر أو الجهتان الأمامية والخلفية للهوية)",
                        "ProofofAddress": "إثبات العنوان",
                        "SourceofIncome": "مصدر الدخل",
                        "AdditionalDocuments": "مستندات إضافية",
                        "ProofofIdentity": "إثبات الهوية",
                        "CopyofPassport": "نسخة من جواز السفر",
                        "CopyofGovernmentIssuedID": "نسخة من بطاقة الهوية الصادرة عن الحكومة",
                        "ProofofResidentialAddress": "إثبات العنوان السكني",
                        "UtilityBill": "فاتورة خدمات",
                        "Payslip": "قسيمة الراتب",
                        "NoteDocuments": "ملاحظة: يجب إصدار المستندات في آخر 3 أشهر تقويمية",
                        "EmploymentContracttoshowyourincomefunds": "عقد العمل لإظهار مصادر دخلك",
                        "CurrentLease/TenancyAgreementSourceOfIncome": "عقد الإيجار الحالي / اتفاقية الإيجار - مصدر الدخل",
                        "Recentbankstatementshowingsalary": "كشف حساب بنكي حديث يظهر الراتب",
                        "Plateform": "المنصة",
                        "Iran": "إيران",
                        "DealId": "معرف الصفقة",
                        "Entry": "إدخال",
                        "Iraq": "العراق",
                        "Turkey": "تركيا",
                        "Jordan": "الأردن",
                        "Pakistan": "باكستان",
                        "India": "الهند",
                        "PayPal": "باي بال"
                    }
                }
            },
            fa: {
                "translation": {
                    "description": {
                        "Dashboard": "داشبورد",
                        "Accounts": "حساب‌ها",
                        "LiveAccounts": "حساب‌های واقعی",
                        "DemoAccounts": "حساب‌های دمو",
                        "ChangePassword": "تغییر رمز عبور",
                        "Deposit": "واریز",
                        "Withdrawals": "برداشت‌ها",
                        "Withdrawal": "برداشت",
                        "InternalTransfer": "انتقال داخلی",
                        "PaymentHistory": "تاریخچه پرداخت",
                        "TradeHistory": "تاریخچه معاملات",
                        "Platforms": "پلتفرم‌ها",
                        "Platform": "پلتفرم",
                        "Documents": "اسناد",
                        "LiveUpdates": "به‌روزرسانی‌های زنده",
                        "MarketUpdates": "به‌روزرسانی‌های بازار",
                        "MarketOverview": "نمای کلی بازار",
                        "EconomyCalander": "تقویم اقتصادی",
                        "Webinar": "وبینار",
                        "UpcomingWebinar": "وبینار آینده",
                        "Coaching": "آموزش",
                        "KYCStatus": "وضعیت KYC",
                        "Pending": "در انتظار",
                        "Approved": "تأیید شده",
                        "TradingStatus": "وضعیت معاملاتی",
                        "Disabled": "غیرفعال",
                        "Enabled": "فعال",
                        "Profile": "پروفایل",
                        "SignOut": "خروج",
                        "BALANCE": "موجودی",
                        "EQUITY": "حقوق صاحبان سهام",
                        "DEPOSITS": "واریزی‌ها",
                        "WITHDRAWAL": "برداشت",
                        "TradingAccounts": "حساب‌های معاملاتی",
                        "TradingPlatform": "پلتفرم معاملاتی",
                        "AccountNumber": "شماره حساب",
                        "Type": "نوع گذرواژه",
                        "Balance": "موجودی",
                        "Equity": "حقوق صاحبان سهام",
                        "Stopout": "استاپ‌اوت",
                        "Leverage": "اهرم",
                        "Action": "عملیات",
                        "Account": "حساب",
                        "GoDo-Live": "GoDo-Live",
                        "GoDo-Server": "GoDo-Server",
                        "GoDo-Demo": "GoDo-Demo",
                        "Currency": "ارز",
                        "FundNow": "اکنون شارژ کنید",
                        "CreateAccount": "ایجاد حساب",
                        "NewPassword": "رمز عبور جدید",
                        "ConfirmNewPassword": "تأیید رمز عبور جدید",
                        "Submit": "ارسال",
                        "CardPayment": "واریز فوری با کارت",
                        "WireTransfer": "انتقال بانکی",
                        "CryptoDetails": "واریز فوری با ارز دیجیتال",
                        "PerfectMoney": "پرفکت مانی",
                        "SticPayment": "پرداخت Stic",
                        "Skrill": "اسکریل",
                        "Neteller": "نتلر",
                        "WishMoney": "ویش مانی",
                        "LocalDeposit": "واریز محلی",
                        "Morocco": "مراکش",
                        "DepositCurrency": "ارز واریز",
                        "AmountInUSD": "مبلغ به دلار",
                        "AmountInMAD": "مبلغ به درهم مراکش",
                        "Note": "یادداشت",
                        "TradingAccount": "حساب معاملاتی",
                        "AfrAsiaBankUSD": "بانک آفرازیای دلار",
                        "AfrAsiaBankEUR": "بانک آفرازیای یورو",
                        "AfrAsiaBankAED": "بانک آفرازیای درهم",
                        "CBDBank": "بانک CBD",
                        "EqualsMoney": "ایکوآلز مانی",
                        "Currencies": "ارزها",
                        "BeneficiaryName": "نام ذینفع",
                        "BeneficiaryAccount": "حساب ذینفع",
                        "BeneficiaryAddress": "آدرس ذینفع",
                        "BankName": "نام بانک",
                        "ID": "شناسه",
                        "BankAddress": "آدرس بانک",
                        "IBANNumber": "شماره IBAN",
                        "SwiftCode": "کد سویفت",
                        "MinimumAmount": "حداقل مبلغ",
                        "AccountForClient": "حساب برای مشتری",
                        "AfrAsiaBankLtd": "بانک آفرازیای Ltd",
                        "GODOLTD-CLIENTACCOUNT": "حساب مشتری GODOLTD",
                        "3rdFloor,StandardCharteredTower,Cybercity,Ebene,72201,Mauritius": "طبقه سوم، برج استاندارد چارتر، سایبرسیتی، ابن، 72201، موریس",
                        "Bowen Square 10, Dr Ferriere Street, Port Louis, Mauritius": "بوون اسکوئر 10، خیابان دکتر فریر، پورت لوئیس، موریس",
                        "OnlineFunding": "تأمین مالی آنلاین",
                        "Pleasenotecrypto": "لطفاً توجه داشته باشید واریزهای ارز دیجیتال فقط با ارزهای USDT و USDC پذیرفته می‌شود، با شرایط زیر برای حفظ صحت تراکنش‌ها",
                        "The customer is responsible for any gas fees applied by the exchange": "مشتری مسئولیت هزینه‌های گس اعمال شده توسط صرافی را بر عهده دارد",
                        "Withdrawals will be blocked for a period of 15 days unless the account has been cleared earlier by our Compliance department": "برداشت‌ها به مدت 15 روز مسدود می‌شود مگر اینکه حساب قبلاً توسط بخش تطابق ما پاک شده باشد",
                        "All funds to be returned to original funding source": "همه وجوه به منبع تأمین مالی اصلی بازگردانده می‌شود",
                        "TradingAccountID": "شناسه حساب معاملاتی",
                        "DepositAmountinUSD": "مبلغ واریز به دلار",
                        "WalletAddress": "آدرس کیف پول",
                        "Iacceptthe": "من قبول می‌کنم",
                        "TermsandConditionsforCreditCardDisclaimer": "شرایط و ضوابط برای بیانیه سلب مسئولیت کارت اعتباری",
                        "Next": "بعدی",
                        "PayeeAccount": "حساب گیرنده",
                        "SupportService": "خدمات پشتیبانی",
                        "Doyouhaveaquestion": "سؤالی در مورد واریز دارید؟ لطفاً با",
                        "ourFinancialSupportService": "خدمات پشتیبانی مالی ما تماس بگیرید.",
                        "Information": "اطلاعات",
                        "Exchangeratesandcommission": "نرخ‌های تبادل و کمیسیون",
                        "Paymentagentcontacts": "اطلاعات تماس با نماینده پرداخت",
                        "AgentName": "نام نماینده",
                        "MoroccoPaymentAgent": "نماینده پرداخت مراکش",
                        "Website": "وب‌سایت",
                        "WorkingHours": "ساعات کاری",
                        "WhishMoneyPaymentInstructions": "دستورالعمل‌های پرداخت ویش مانی",
                        "Step-by-Step Instructions1. Download and Install the Wish Money App": "دستورالعمل‌های گام به گام 1. دانلود و نصب اپلیکیشن ویش مانی",
                        "Visit the Wish Money App download page.": "از صفحه دانلود اپلیکیشن ویش مانی بازدید کنید.",
                        "Download and install the app on your mobile device.": "اپلیکیشن را بر روی دستگاه موبایل خود دانلود و نصب کنید.",
                        "Login to the Wish Money App": "ورود به اپلیکیشن ویش مانی",
                        "Open the app.": "اپلیکیشن را باز کنید.",
                        "Enter your login credentials to access your account.": "اطلاعات ورود خود را وارد کنید تا به حساب خود دسترسی پیدا کنید.",
                        "Navigate to the Finance Category": "به دسته مالی بروید",
                        "After logging in, choose the 'Finance' category from the menu options.": "پس از ورود، دسته 'مالی' را از منوی گزینه‌ها انتخاب کنید.",
                        "Enter the Receiver's Phone Number": "شماره تلفن گیرنده را وارد کنید",
                        "In the designated field, input the receiver's phone number: 71180175.": "در فیلد تعیین شده، شماره تلفن گیرنده را وارد کنید: 71180175.",
                        "Specify the Transfer Amount": "مبلغ انتقال را مشخص کنید",
                        "Enter the amount you wish to transfer in USD.": "مبلغی که می‌خواهید به دلار منتقل کنید را وارد کنید.",
                        "Add a Note with Your Trading Account Details": "یک یادداشت با جزئیات حساب معاملاتی خود اضافه کنید",
                        "In the note field, enter your trading account number followed by - GoDoCM Dubai. Example: 12345678 - GoDoCM Dubai.": "در فیلد یادداشت، شماره حساب معاملاتی خود را وارد کنید و سپس - GoDoCM Dubai. مثال: 12345678 - GoDoCM Dubai.",
                        "Complete the Transaction": "تراکنش را تکمیل کنید",
                        "Review all the details to ensure accuracy.": "تمام جزئیات را برای اطمینان از صحت بررسی کنید.",
                        "Click the 'Send' button to complete the transaction.": "برای تکمیل تراکنش، دکمه 'ارسال' را کلیک کنید.",
                        "WithdrawalMethod": "روش برداشت",
                        "Amount": "مبلغ",
                        "ImportantInformation": "اطلاعات مهم",
                        "Allfunds": "همه وجوه به منبع تأمین مالی اصلی بازگردانده می‌شوند.",
                        "Youareentitled": "شما حق دارید یک برداشت رایگان در ماه انجام دهید؛ پس از آن، هزینه استاندارد برداشت 25 دلار اعمال می‌شود.",
                        "FromAccount": "از حساب",
                        "ToAccount": "به حساب",
                        "ReportType": "نوع گزارش",
                        "DateFrom": "تاریخ از",
                        "DateTo": "تاریخ تا",
                        "Search": "جستجو",
                        "Time": "زمان",
                        "Status": "وضعیت",
                        "PENDING": "در انتظار",
                        "REJECTED": "رد شده",
                        "APPROVED": "تأیید شده",
                        "AccountType": "نوع حساب",
                        "PROCEED": "ادامه",
                        "ViewTrades": "مشاهده معاملات",
                        "OpenPosition": "باز کردن موقعیت",
                        "ClosePosition": "بستن موقعیت",
                        "Symbol": "نماد",
                        "Ticket": "بلیط",
                        "DealType": "نوع معامله",
                        "Volume": "حجم",
                        "Price": "قیمت",
                        "PriceOpen": "قیمت باز",
                        "PriceSI": "قیمت SI",
                        "PriceTP": "قیمت TP",
                        "PriceCurrent": "قیمت جاری",
                        "Login": "ورود",
                        "NoData": "داده‌ای وجود ندارد",
                        "DownloadsForMT5": "دانلودها برای MT5",
                        "DownloadsForMT4": "دانلودها برای MT4",
                        "DownloadNow": "همین حالا دانلود کنید",
                        "forAndroid": "برای اندروید",
                        "forWindow": "برای ویندوز",
                        "forMAC": "برای مک",
                        "forPC": "برای رایانه",
                        "UploadDocuments": "بارگذاری اسناد",
                        "ProofofID": "مدرک شناسایی (صفحه اول گذرنامه یا جلو و پشت کارت شناسایی)",
                        "ProofofAddress": "مدرک نشانی",
                        "SourceofIncome": "منبع درآمد",
                        "AdditionalDocuments": "اسناد اضافی",
                        "ProofofIdentity": "مدرک هویت",
                        "CopyofPassport": "کپی گذرنامه",
                        "CopyofGovernmentIssuedID": "کپی کارت شناسایی صادر شده توسط دولت",
                        "ProofofResidentialAddress": "مدرک نشانی مسکونی",
                        "UtilityBill": "قبض خدماتی",
                        "CurrentLease/TenancyAgreementSourceOfIncome": "اجاره نامه فعلی / قرارداد اجاره، منبع درآمد",
                        "Recentbankstatementshowingsalary": "صورت‌حساب بانکی اخیر که حقوق را نشان می‌دهد",
                        "Payslip": "فیش حقوقی",
                        "EmploymentContracttoshowyourincomefunds": "قرارداد کاری برای نشان دادن منابع درآمد شما",
                        "NoteDocuments": "توجه: اسناد باید در سه ماه اخیر صادر شده باشند",
                        "Title": "عنوان",
                        "UploadedAt": "بارگذاری شده در",
                        "KYCDocuments": "اسناد KYC",
                        "OPENANEWDEMOACCOUNT": "باز کردن یک حساب دمو جدید",
                        "OPENANEWLIVEACCOUNT": "باز کردن یک حساب واقعی جدید",
                        "File": "فایل",
                        "WhishMoneyInstructions": "برای انتقال وجوه با استفاده از اپلیکیشن ویش مانی، لطفاً مراحل زیر را دنبال کنید:",
                        "StepByStepInstructions": "دستورالعمل‌های گام به گام",
                        "Step1": "1. دانلود و نصب اپلیکیشن ویش مانی:",
                        "Step1Details": "بازدید از.",
                        "Step1Details1": "صفحه دانلود اپلیکیشن ویش مانی.",
                        "Step1Details2": "اپلیکیشن را بر روی دستگاه موبایل خود دانلود و نصب کنید.",
                        "Step2": "2. ورود به اپلیکیشن ویش مانی:",
                        "Step2Details": "اپلیکیشن را باز کنید.",
                        "Steps2Details1": "اطلاعات ورود خود را وارد کنید تا به حساب خود دسترسی پیدا کنید.",
                        "Step3": "3. به دسته مالی بروید:",
                        "Step3Details": "پس از ورود، دسته 'مالی' را از منوی گزینه‌ها انتخاب کنید.",
                        "Step4": "4. شماره تلفن گیرنده را وارد کنید:",
                        "Step4Details": "در فیلد تعیین شده، شماره تلفن گیرنده را وارد کنید: 71180175.",
                        "Step5": "5. مبلغ انتقال را مشخص کنید:",
                        "Step5Details": "مبلغی که می‌خواهید به دلار منتقل کنید را وارد کنید.",
                        "Step6": "6. یک یادداشت با جزئیات حساب معاملاتی خود اضافه کنید:",
                        "Step6Details": "در فیلد یادداشت، شماره حساب معاملاتی خود را وارد کنید و سپس - GoDoCM Dubai. مثال: 12345678 - GoDoCM Dubai.",
                        "Step6Details1": "اطلاعات ورود خود را وارد کنید تا به حساب خود دسترسی پیدا کنید",
                        "Step7": "7. تراکنش را تکمیل کنید:",
                        "Step7Details": "تمام جزئیات را برای اطمینان از صحت بررسی کنید.",
                        "Steps7Details1": "برای تکمیل تراکنش، دکمه 'ارسال' را کلیک کنید.",
                        "CryptoCurrency": "ارز دیجیتال",
                        "Network": "شبکه",
                        "Address": "آدرس",
                        "PleaseNoteMinimumWithdrawal": "لطفاً توجه داشته باشید حداقل مبلغ برداشت 10 دلار است",
                        "AddWallet": "افزودن کیف پول",
                        "Wallet": "کیف پول",
                        "AddNewWallet": "افزودن کیف پول جدید",
                        "Add": "افزودن",
                        "Profit": "سود",
                        "Plateform": "پلتفرم",
                        "Iran": "ایران",
                        "DealId": "شناسه معامله",
                        "Entry": "ورود",
                        "Iraq": "عراق",
                        "Turkey": "ترکیه",
                        "Jordan": "اردن",
                        "Pakistan": "پاکستان",
                        "India": "هند",
                        "PayPal": "پی پال"
                    }
                }
            }
        }
    });

export default i18n; 