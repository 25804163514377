import React, { Fragment, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Loader from "./Layouts/Loader/Loader";

import './config/i18n';

const App = lazy(() => import("./components/app"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const Login = lazy(() => import("./components/Authentication/Login/Login"));
const SilentLogin = lazy(() => import("./components/Authentication/silent-login"));
const ForgetPassword = lazy(() => import("./components/Pages/ForgetPassword"));
const ResetLink = lazy(() => import("./components/Pages/ForgetPassword/resetLink"));
const AuthenticationPage = lazy(() =>
  import("./components/AuthenticationPage")
);

const Editprofile = lazy(() =>
  import("./components/Pages/Editprofile/Editprofile")
);
const BankAccounts = lazy(() =>
  import("./components/Pages/BankAccounts/BankAccounts")
);
const Wallets = lazy(() => import("./components/Pages/Wallets/Wallets"));
// const Logs = lazy(() => import("./components/Pages/Logs/Logs"));
const Kyc = lazy(() => import("./components/Pages/Kyc/Kyc"));
const LiveAccounts = lazy(() =>
  import("./components/Pages/LiveAccounts/LiveAccounts")
);
const DemoAccounts = lazy(() =>
  import("./components/Pages/DemoAccounts/DemoAccounts")
);
const ChangePassword = lazy(() =>
  import("./components/Pages/ChangePassword/index")
);
const Withdrawal = lazy(() => import("./components/Pages/Withdrawal"));
const Deposit = lazy(() => import("./components/Pages/Deposit"));
const StripeSuccess = lazy(() => import("./components/Pages/StripeSuccess"));
const SolidPay = lazy(() => import("./components/Pages/Deposit/SolidPayment"));
const InternalTransfer = lazy(() =>
  import("./components/Pages/Internal-transfer")
);
const Report = lazy(() => import("./components/Pages/Report"));
const Trades = lazy(() => import("./components/Pages/Trades"));
const Platforms = lazy(() => import("./components/Pages/Platforms/Platforms"));
const Webinar = lazy(() => import("./components/Pages/Training/webinar"));
const TechnicalAnalysis = lazy(() => import("./components/Pages/Training/technicalAnalysis"));
const MarketOverView = lazy(() => import("./components/Pages/Training/marketOverview"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<AuthenticationPage />}
            >
              <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                element={<Login />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/silent-login`}
                element={<SilentLogin />}
              />
              {/* <Route path={`${process.env.PUBLIC_URL}/authentication/register`} element={<Register />} />*/}
              <Route
                path={`${process.env.PUBLIC_URL}/forget-password`}
                element={<ForgetPassword />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/reset-password`}
                element={<ResetLink />}
              />
            </Route>

            <Route element={<App />}>
              <Route
                path={`${process.env.PUBLIC_URL}/dashboard`}
                element={<Dashboard />}
              />
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/profile`}
                  element={<Editprofile />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/bank-accounts`}
                  element={<BankAccounts />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/wallets`}
                  element={<Wallets />}
                />
                ,
                {/*<Route
                  path={`${process.env.PUBLIC_URL}/profile/logs`}
                  element={<Logs />}
                />*/}
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/profile/kyc`}
                  element={<Kyc />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/accounts/live`}
                  element={<LiveAccounts />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/accounts/demo`}
                  element={<DemoAccounts />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/accounts/change-password`}
                  element={<ChangePassword />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/deposit`}
                  element={<Deposit />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/stripe_success`}
                  element={<StripeSuccess />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/deposit/SolidPayment`}
                  element={<SolidPay />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/withdrawal`}
                  element={<Withdrawal />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/internal-transfer`}
                  element={<InternalTransfer />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/reports`}
                  element={<Report />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/trades`}
                  element={<Trades />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/platform`}
                  element={<Platforms />}
                />,
                <Route
                  path={`${process.env.PUBLIC_URL}/webinar`}
                  element={<Webinar />}
                />,
                <Route
                  path={`${process.env.PUBLIC_URL}/technical_analysis`}
                  element={<TechnicalAnalysis />}
                />,
                <Route
                  path={`${process.env.PUBLIC_URL}/market_overview`}
                  element={<MarketOverView />}
                />
              </Route>
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>

    </Fragment>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
